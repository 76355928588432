import React, { useEffect, useState } from "react";
import Dropdown from "../ui/dropdown/dropdown";
import all_filters from "../../images/all-filters.svg";
import "./filters.css";
import { useDispatch, useSelector } from "react-redux";
import { brandsApi } from "../api/brandsService";
import { categoriesApi } from "../api/categoriesService";
import { modelsApi } from "../api/modelsService";
import { colorsApi } from "../api/colorsService";
import { servicesApi } from "../api/servicesService";
import { changeStateOpenAllFiltersPopup } from "../../store/popupSlice";
import { changeStatePreloader } from "../../store/toolkitSlice";
const Filters = () => {
	const [count, setCount] = useState({
		'Категории' : 0,
		"Марка авто" : 0,
		"Модель авто" : 0,
		'Услуги' : 0,
		'Цвет' : 0,
	});


	const dispatch = useDispatch();
	const pageWidth = useSelector((state) => state.toolkit.pageWidth);
	const filtersBrand = useSelector(
		(state) => state.filters.filtersBrand[0]?.slug
	);
	const { data: brands, isLoading: isFetchingBrands } =
		brandsApi.useGetBrandsQuery();
	const { data: categories, isLoading: isFetchingCategories } =
		categoriesApi.useGetCategoriesQuery();
	const { data: brands_models, isLoading: isFetchingModels } =
		modelsApi.useGetModelsQuery(
			filtersBrand ? `?brand=${filtersBrand}` : ""
		);
	const { data: colors, isLoading: isFetchingColors } =
		colorsApi.useGetColorsQuery();
	const { data: services, isLoading: isFetchingServices } =
		servicesApi.useGetServicesQuery();

	useEffect(() => {
		if (
			isFetchingBrands  ||
			isFetchingCategories  ||
			isFetchingModels ||
			isFetchingColors  ||
			isFetchingServices 
		) {
			dispatch(changeStatePreloader(true));
		} else {
			dispatch(changeStatePreloader(false));
		}
	}, [
		isFetchingBrands,
		isFetchingCategories,
		isFetchingModels,
		isFetchingColors,
		isFetchingServices,
		dispatch,
	]);

	return (
		pageWidth > 767 && (
			<div className="filters">
				<div className="filters__container">
					<Dropdown text="Марка авто" dropdownData={brands} count={count["Марка авто"]} setCount={setCount} />
					<Dropdown text="Модель авто" dropdownData={brands_models} count={count["Модель авто"]} setCount={setCount} />
					{pageWidth > 939 && (
						<Dropdown text="Категории" dropdownData={categories}  count={count.Категории} setCount={setCount}/>
					)}
					{pageWidth > 1139 && (
						<Dropdown text="Услуги" dropdownData={services}  count={count.Услуги} setCount={setCount}/>
					)}
					{pageWidth > 1439 && (
						<Dropdown text="Цвет" dropdownData={colors} count={count.Цвет} setCount={setCount} />
					)}
					<button
						className="allFilters"
						type="button"
						onClick={() =>
							dispatch(changeStateOpenAllFiltersPopup(true))
						}>
						<img src={all_filters} alt="all filters" />
						<p className="allFilters-text">Все фильтры</p>
					</button>
				</div>
			</div>
		)
	);
};

export default Filters;
