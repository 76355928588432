import React from "react";
import Catalog from "../components/catalog/catalog";
import Filters from "../components/filters/filters";
import "./main.css";
import { useSelector } from "react-redux";

const Main = () => {
	const authorized = useSelector((state) => state.auth.authorized);
	return (
		authorized &&
			<section className="main">
				<Filters />
				<Catalog />
			</section>
	);
};

export default Main;
