import "./dropdown.css";
import React, { useCallback, useMemo } from "react";
import arrow_drop_down from "../../../images/dropdown/select-icon.svg";
import arrow_drop_up from "../../../images/dropdown/select-icon-true.svg";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-dropdown-select";
import { setFiltersBrand, setFiltersCategory, setFiltersColor, setFiltersModel, setFiltersService } from "../../../store/filtersSlice";

const Dropdown = ({ text, dropdownData, count, setCount }) => {
	const dispatch = useDispatch()
	const {filtersModel, filtersCategory,filtersBrand, filtersService, filtersColor} = useSelector((state) => state.filters);
	const openAllFiltersPopup = useSelector(
		(state) => state.popup.openAllFiltersPopup
	);

	
	const handleChange = useCallback(
		(newValues) => {
			const updateFilters = (currentFilters, newValues) => {
				const itemsToRemove = currentFilters.filter(
					(filter) => !newValues.some((item) => item.id === filter.id)
				);
				const itemsToAdd = newValues.filter(
					(item) => !currentFilters.some((filter) => filter.id === item.id)
				);
				return [
					...currentFilters.filter(
						(filter) => !itemsToRemove.some((item) => item.id === filter.id)
					),
					...itemsToAdd,
				];
			};
			switch (text) {
				case "Категории": {
					const updatedFilters = updateFilters(filtersCategory, newValues);
					dispatch(setFiltersCategory(updatedFilters));
					setCount((prev) => ({
						...prev,
						Категории: updatedFilters.length,
					}));
					break;
				}
				case "Марка авто": {
						 if (filtersBrand[0]?.id === newValues[0]?.id && !openAllFiltersPopup) {
							dispatch(setFiltersBrand([]));
							setCount((prev) => ({
								...prev,
								"Марка авто": 0,
							}));
						} else {
							const updatedFilters = updateFilters(filtersBrand, newValues);
							dispatch(setFiltersBrand(updatedFilters));
							setCount((prev) => ({
								...prev,
								"Марка авто": updatedFilters.length,
							}));
						}
						break;
				}
				case "Модель авто": {
					const updatedFilters = updateFilters(filtersModel, newValues);
					dispatch(setFiltersModel(updatedFilters));
					setCount((prev) => ({
						...prev,
						"Модель авто": updatedFilters.length,
					}));
					break;
				}
				case "Услуги": {
					const updatedFilters = updateFilters(filtersService, newValues);
					dispatch(setFiltersService(updatedFilters));
					setCount((prev) => ({
						...prev,
						Услуги: updatedFilters.length,
					}));
					break;
				}
				case "Цвет": {
					const updatedFilters = updateFilters(filtersColor, newValues);
					dispatch(setFiltersColor(updatedFilters));
					setCount((prev) => ({
						...prev,
						Цвет: updatedFilters.length,
					}));
					break;
				}
				default:
					break;
			}
		},
		[filtersCategory, filtersBrand, filtersModel, filtersService, filtersColor, text, dispatch, setCount, openAllFiltersPopup]
	);
	

	const actualValue = useMemo(() => {
		switch (text) {
			case "Категории":
			return filtersCategory
				case "Марка авто":
				return filtersBrand;
				case "Модель авто":
				return filtersModel
				case "Услуги":
				return filtersService
				case "Цвет":
				return filtersColor
			default:
				break;
		}
	}, [text, filtersColor, filtersService, filtersModel, filtersCategory, filtersBrand])

	return (
		<div className="dropdown">
			<Select
				className="dropdown-select"
				options={dropdownData}
				multi={text !== "Марка авто"}
				disabled={text === "Модель авто" && filtersBrand.length === 0}
				valueField="id"
				labelField="title"
			    values={actualValue}
				itemRenderer={({ item, methods }) => (
					<div className="select__element">
						{item.disabled ? (
							<div className="element">{item.title}</div>
						) : (
							<div
								onClick={() => methods.addItem(item)}
								className="element">
								<input
									className="custom-checkbox"
									onChange={() => methods.addItem(item)}
									type="checkbox"
									checked={methods.isSelected(item)}
									id="checked"
									name="checked"
								/>
								<div
									className="element__text"
									htmlFor="checked">
									{text === "Цвет" && (
										<div
											className="element__color"
											style={{
												backgroundColor: `${item.hex_code}`,
											}}
										/>
									)}
									<p>{item.title}</p>
								</div>
							</div>
						)}
					</div>
				)}
				onChange={(values) => handleChange(values)}
				closeOnSelect
				contentRenderer={() => (
					<div className="placeholderFilters">
						{text}
						{count !== 0 && (<div className="countFilters">{count}</div>
						)}
					</div>
				)}
				dropdownHandleRenderer={({ state }) => (
					<span className="dropdown__arrow">
						<img
							src={
								state.dropdown ? arrow_drop_up : arrow_drop_down
							}
							alt="dropdown__item_select_icon"
						/>
					</span>
				)}
			/>
		</div>
	);
};

export default Dropdown;
