import React, { useCallback, useEffect, useState } from "react";
import Main from "../../pages/main";
import Header from "../header/header";
import Popup from "../popup/popup";
import "./app.css";
import { Route, Routes, useNavigate } from "react-router-dom";
import Account from "../../pages/account/account";
import { useDispatch, useSelector } from "react-redux";
import Auth from "../../pages/auth/auth";
import Forgot from "../../pages/auth/forgot";
import Confirm from "../../pages/auth/confirm";
import New from "../../pages/auth/new";
import Done from "../../pages/auth/done";
import ChangeName from "../popup/changePopups/changeName";
import ChangePassword from "../popup/changePopups/changePassword";
import { changePageWidth } from "../../store/toolkitSlice";
import Footer from "../footer/footer";
import { changeStateAuthorized, changeUserData } from "../../store/authSlice";
import { authApi } from "../api/authService";
import CreateCardPopup from "../popup/createCard/createCardPopup";
import NotFound from "../../pages/404/404";
import AddCategory from "../popup/addCategory";
import ChangeCategory from "../popup/changePopups/changeCategory";
import AllFilters from "../filters/allFilters/allFilters";
import Preloader from "../preloader/preloader";
import ApprovePopup from "../popup/changePopups/approvePopup";
import { closeAllPopups } from "../../store/popupSlice";

function App() {
	
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {authStatus} = useSelector((state) => state.auth);
	const {cardInfo} = useSelector((state) => state.popup);
	const [, setPageWidth] = useState(window.innerWidth);
	const [setToken] = authApi.useVerifyTokenMutation();
	const [refreshToken] = authApi.useRefreshTokenMutation();

	const getUserData = useCallback(async () => {
		try {
		  const response = await fetch("https://photobaza.com/api/v1/users/me", {
			method: "GET",
			headers: {
			  Authorization: `Bearer ${localStorage.getItem("access")}`,
			},
		  });
		  const data = await response.json();
		  dispatch(changeUserData(data));
		} catch (error) {
		  console.error("Ошибка получения данных пользователя:", error);
		}
	  }, [dispatch]); 

	useEffect(() => {
		(async () => {
	    const currentPath = window.location.pathname;
		const accessToken = localStorage.getItem("access");
		const refreshTokenValue = localStorage.getItem("refresh");
		if (accessToken) {
			const verifyTokenResult = await setToken();
			if (!verifyTokenResult.error) {
				dispatch(changeStateAuthorized(true));
				getUserData();
				if (currentPath === "/" || currentPath === "/auth") {
					navigate("/main");
				}
			} else {
            localStorage.clear()
            navigate("/auth"); 
			dispatch(changeStateAuthorized(false));
			}
		} else if (refreshTokenValue) {
			const refresh = await refreshToken();
			localStorage.setItem('access', refresh.data.access)
			await getUserData();
			dispatch(changeStateAuthorized(true));
		} else {
			dispatch(changeStateAuthorized(false));
			if (currentPath !== "/auth") {
				navigate("/auth");
				localStorage.clear()
			}
		}})()
		
	}, [dispatch, navigate, refreshToken, setToken, getUserData]);
		
	useEffect(() => {
		const handleResize = () => {
			const newWidth = window.innerWidth;
			setPageWidth(newWidth)
			dispatch(changePageWidth(newWidth)); 
		};
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, [dispatch]); 


	useEffect(() => {
		const handleKeyDown = (e) => {
			if (e.keyCode === 27) {
				dispatch(closeAllPopups());
			}
		};
		window.addEventListener("keydown", handleKeyDown);
		return () => window.removeEventListener("keydown", handleKeyDown);
	}, [dispatch]);


	useEffect(() => {
		function newPageSize() {
			setPageWidth(window.innerWidth);
			dispatch(changePageWidth(window.innerWidth));
		}

		window.addEventListener("resize", newPageSize);
		return () => window.removeEventListener("resize", newPageSize);
	}, [dispatch]);
	

	return (
		<div className="app">
			<Header />
			<Routes>
				<Route exact path="/" element={<Main />} />
				<Route path="/account/" element={<Account />} />
				<Route path="/main/" element={<Main />} />
				<Route
					path="/auth"
					element={
						authStatus === "login" ? (
							<Auth />
						) : authStatus === "forgot" ? (
							<Forgot />
						) : authStatus === "confirm" ? (
							<Confirm />
						) : authStatus === "new" ? (
							<New />
						) : (
							<Done />
						)
					}
				/>
				<Route path="/*" element={<NotFound />} />
			</Routes>
			{cardInfo && <Popup />}
			<ChangeName />
			<ChangeCategory />
			<ChangePassword />
			<CreateCardPopup />
			<AddCategory />
			<AllFilters />
			<Preloader />
			<ApprovePopup />
			<Footer />
		</div>
	);
}

export default App;
