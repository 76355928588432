import React, { useEffect, useState } from "react";
import "./createCardPopup.css";
import { useDispatch, useSelector } from "react-redux";
import {
	changeStateOpenPopupActions,
	changeStateOpenPopupCreateCard,
	setCardInfo,
} from "../../../store/popupSlice";
import closeOverlay from "../../../images/popup/close-overlay-create-card.svg";
import { userApi } from "../../api/userService";
import Button from "../../ui/buttons/button";
import { productsApi } from "../../api/productsService";
import CreateCardInputs from "./createCardInputs";
import CreateCardDate from "./createCardDate";
import {
	setBrand_models,
	setCategories,
} from "../../../store/newProductDataSlice";

export default function CreateCardPopup() {
	const dispatch = useDispatch();
	const {pageWidth} = useSelector((state) => state.toolkit);
	const {brand_models,categories, services, colors, tags } = useSelector((state) => state.product);
	const {openPopupCreateCard, cardInfo} = useSelector((state) => state.popup);
	const [files, setFiles] = useState();
	const [activePreloader, setActivePreloader] = useState(false);
	const [filesError, setFilesError] = useState(false);
	const [article, setArticle] = useState(cardInfo?.article);
	const [articleError, setArticleError] = useState(false);
	const [titleCard, setTitleCard] = useState(cardInfo?.title);
	const [titleCardError, setTitleCardError] = useState(false);
	const [modelsError, setModelsError] = useState(false);
	const [categoryError, setCategoryError] = useState(false);
	const { data: userData } = userApi.useGetUserQuery();
	const formData = new FormData();

	useEffect(() => {
		setArticle(cardInfo?.article);
		setTitleCard(cardInfo?.title);
	}, [cardInfo]);

	useEffect(() => {
		if (!openPopupCreateCard) {
			setArticle("");
			setTitleCard("");
			setArticleError(false);
			setTitleCardError(false);
			setModelsError(false);
			setCategoryError(false);
			setFilesError(false);
			dispatch(setBrand_models([]));
			dispatch(setCategories([]));
			dispatch(setCategories([]));
			setFiles();
		}
	}, [openPopupCreateCard, dispatch]);

	function SetFormData() {
		formData.append("title", titleCard);
		if (files) {
			files.forEach((file) => {
				formData.append("file", file);
			});
		}
		formData.append("article", [article]);
		brand_models?.forEach((brand_model) => {
			formData.append("brand_models", brand_model);
		});
		categories.forEach((category) => {
			formData.append("categories", category);
		});
		colors.forEach((color) => {
			formData.append("colors", color);
		});
		services.forEach((service) => {
			formData.append("services", service);
		});
		tags.forEach((tag) => {
			formData.append("tags", tag);
		});
	}

	useEffect(() => {
		if (titleCard !== "") setTitleCardError(false);
		if (article !== "") setArticleError(false);
		if (brand_models.length) setModelsError(false);
		if (categories.length) setCategoryError(false);
		if (files) setFilesError(false);
	}, [titleCard, article, brand_models, categories, files]);

	async function HandleSubmitNewCard(e) {
		setArticle(cardInfo?.article);
		setTitleCard(cardInfo?.title);
		e.preventDefault();
		if (titleCard && article && brand_models.length && categories.length) {
			await SetFormData();
			if (cardInfo) {
				await putProductData({ slug: cardInfo.slug, data: formData });
				dispatch(changeStateOpenPopupCreateCard(false));
				dispatch(setCardInfo(null));
			} else {
				if (brand_models && files) {
					await postProductData(formData);
					dispatch(changeStateOpenPopupCreateCard(false));
					dispatch(setCardInfo(null));
				} else {
					if(!brand_models) setModelsError(true);
					if (!files) setFilesError(true);
				}
			}
			
		} else {
			if (titleCard === "") setTitleCardError(true);
			if (article === "") setArticleError(true);
			if (brand_models.length === 0) setModelsError(true);
			if (categories.length === 0) setCategoryError(true);
			if (!files) setFilesError(true);
		}
	}
	const [
		putProductData,
		{ data: successPut, error: ErrorPut, isLoading: isFetchingProductPut },
	] = productsApi.usePutProductMutation();

	const [
		postProductData,
		{ data: successCreateCard, error: Error, isLoading: isFetchingProduct },
	] = productsApi.useCreateProductsMutation();

	useEffect(() => {
		if (isFetchingProduct || isFetchingProductPut) {
			setActivePreloader(true);
		} else {
			setActivePreloader(false);
		}
	}, [isFetchingProduct, isFetchingProductPut]);

	useEffect(() => {
		if (successCreateCard) {
		}
	}, [successPut, ErrorPut, successCreateCard]);

	function fileToFetch(images) {
		setFiles(images);
	}

	function handleClosePopupCreateCard() {
		dispatch(changeStateOpenPopupCreateCard(false));
		dispatch(changeStateOpenPopupActions(""));
	}

	return (
		openPopupCreateCard && (
			<form className="createCardPopup" onSubmit={HandleSubmitNewCard}>
				{pageWidth > 767 && (
					<button
						className="createCardPopup__overlay"
						type="button"
						onClick={handleClosePopupCreateCard}
					/>
				)}
				<div className="createCardPopup__container">
					{pageWidth < 768 && (
						<div className="createCardPopup__title">
							<h4>Создать</h4>
							<button type="button">
								<img
									onClick={handleClosePopupCreateCard}
									src={closeOverlay}
									alt="close-button"
									className="createCardPopup__close"
								/>
							</button>
						</div>
					)}
					<div className="createCardPopup__head">
						<div className="userAndDate">
							<p className="user">{userData?.full_name}</p> &#183;{" "}
							<CreateCardDate />
						</div>
						{pageWidth > 767 && (
							<button type="button">
								<img
									onClick={() =>
										dispatch(
											changeStateOpenPopupCreateCard(
												false
											)
										)
									}
									src={closeOverlay}
									alt="close-button"
									className="createCardPopup__close"
								/>
							</button>
						)}
					</div>
					<div className="newCardClear">
						<div className="newCardClear__input-box">
							<h4 className="newCardClear__input-box-text">
								Название
							</h4>
							<div className="newCardClear__input_block">
								<input
									type="text"
									id="newCardName"
									name="newCardName"
									value={titleCard === "" && cardInfo?.title ? cardInfo.title : titleCard}
									onChange={(e) =>
										setTitleCard(e.target.value)
									}
									className="newCardClear__input photo"
									placeholder="Введите название"
									required
								/>
								{titleCardError && (
									<span className="newCardClear__input_error">
										Это поле не может быть пустым
									</span>
								)}
							</div>
						</div>
					</div>
					<div className="newCardClear__input-box">
						<h4 className="newCardClear__input-box-text">
							Артикул
						</h4>
						<div className="newCardClear__input_block">
							<input
								id="newCardArticle"
								name="newCardArticle"
								type="text"
								className="newCardClear__input photo"
								value={article === "" && cardInfo?.article ? cardInfo.article : article}
								onChange={(e) => setArticle(e.target.value)}
								placeholder="Введите артикул"
								required
							/>
							{articleError && (
								<span className="newCardClear__input_error">
									Это поле не может быть пустым
								</span>
							)}
						</div>
					</div>
					<CreateCardInputs
						onSetFile={fileToFetch}
						cardInfo={cardInfo}
						modelsError={modelsError}
						categoryError={categoryError}
						filesError={filesError}
					/>
					<div className="newCardButtons">
						<button
							className="buttonNewCard"
							type="button"
							onClick={() =>
								dispatch(changeStateOpenPopupCreateCard(false))
							}>
							<Button textButton="Отмена" type={"secondary"} />
						</button>
						<button
							className={
								cardInfo?.status === "ON_REWORK"
									? "buttonNewCard rework"
									: "buttonNewCard"
							}
							type="submit"
							onClick={HandleSubmitNewCard}>
							<Button
								textButton={
									cardInfo?.status === "ON_REWORK"
										? "Отправить на модернизацию"
										: "Сохранить"
								}
								type={"primary"}
								activePreloader={activePreloader}
							/>
						</button>
					</div>
				</div>
			</form>
		)
	);
}
