import React, { useEffect, useState } from "react";
import "./card.css";
import Filter from "../filters/filter/filter";
import copy from "../../images/cards/copy.svg";
import content_copy from "../../images/cards/content_copy.svg";
import download from "../../images/cards/download.svg";
import { useDispatch, useSelector } from "react-redux";
import {
	changeStateOpenPopup,
	changeStateOpenPopupCreateCard,
	setCardInfo,
} from "../../store/popupSlice";
import { useGetDate } from "../../utils/useGetDate";
import ReactPlayer from "react-player";
import { changeSelectedCard } from "../../store/catalogSlice";
import closeOverlay from "../../images/popup/close-overlay-create-card.svg";
import copyErrorImage from "../../images/popup/error.svg";

const Card = ({ cardInfo }) => {
	const dispatch = useDispatch();
	const [date, setDate] = useState();
	const [play, setPlay] = useState(false);
	const [isCopying, setIsCopying] = useState(false); // Для прелоудера
	const [copySuccess, setCopySuccess] = useState(false); // Для уведомления об успехе
	const [isDownloading, setIsDownloading] = useState(false);
	const [downloadSuccess, setDownloadSuccess] = useState(false);
	const [copyError, setCopyError] = useState("");
	const {twoCardsOnRow,multiSelect, selectedCards} = useSelector((state) => state.catalog);
	const fileType = useSelector((state) => state.filters.fileType);
	const { cardDate, getCardDate } = useGetDate({ date });

	function popupOpen() {
		dispatch(changeStateOpenPopup(true));
		dispatch(setCardInfo(cardInfo));
	}

	const user = useSelector((state) => state.auth.userData);
	useEffect(() => {
		let dateCard = new Date(cardInfo.created_at);
		setDate(dateCard);
		setCopyError("");
	}, [cardInfo.created_at]);

	useEffect(() => {
		if (date) getCardDate(date);
	}, [date, getCardDate]);

	async function HandleCopy() {
		if (!navigator.clipboard || !window.ClipboardItem) {
			setCopyError("Clipboard API не поддерживается в этом браузере");
			return;
		}

		if (document.hasFocus()) {
			try {
				setIsCopying(true); // Показать прелоудер
				const response = await fetch(cardInfo.compressed_file_url, {
					mode: "cors",
					headers: {
						"Cache-Control": "no-cache", // Запрос с параметром no-cache для обхода кэша
					},
				});

				if (!response.ok) {
					throw new Error("Ошибка при загрузке изображения.");
				}

				const blob = await response.blob();

				const img = new Image();
				const reader = new FileReader();

				reader.onloadend = async function () {
					img.src = reader.result;

					img.onload = async function () {
						const canvas = document.createElement("canvas");
						canvas.width = img.width;
						canvas.height = img.height;

						const ctx = canvas.getContext("2d");
						ctx.drawImage(img, 0, 0);

						canvas.toBlob(async function (pngBlob) {
							const clipboardItem = new ClipboardItem({
								"image/png": pngBlob,
							});

							try {
								await navigator.clipboard.write([
									clipboardItem,
								]);
								setCopySuccess(true); // Показать уведомление об успехе
								setTimeout(() => setCopySuccess(false), 2000); // Скрыть уведомление через 2 секунды
							} catch (error) {
								setCopyError(
									"Ошибка при копировании изображения:",
									error
								);
								setCopyError("Не удалось скопировать изображение! Не закрывайте вкладку, пока не завершится копирование");
							} finally {
								setIsCopying(false); // Скрыть прелоудер
							}
						}, "image/png");
					};
				};

				reader.readAsDataURL(blob);
			} catch (error) {
				setCopyError("Ошибка при копировании изображения:", error);
				setIsCopying(false); // Скрыть прелоудер
			}
		} else {
			setCopyError("Не удалось скопировать изображение! \n Не закрывайте вкладку, пока не завершится копирование");
		}
	}

	function HandleOpenPopup() {
		if (cardInfo?.status === "ON_REWORK" && user?.is_admin === false) {
			dispatch(setCardInfo(cardInfo));
			dispatch(changeStateOpenPopupCreateCard(true));
		} else popupOpen(cardInfo.slug);
	}

	function HandleDownload() {
		setIsDownloading(true); // Показать прелоудер
		if (cardInfo.file_url) {
			fetch(cardInfo.file_url, {
				method: "GET",
				headers: {}
			})
				.then(response => {
					response.arrayBuffer().then(function (buffer) {
						const url = window.URL.createObjectURL(new Blob([buffer]));
						const link = document.createElement("a");
						link.href = url;
						if (fileType === "Video") {
							link.setAttribute("download", `${cardInfo.title}.mp4`);
						} else {
							link.setAttribute("download", `${cardInfo.title}.png`);
						}
						document.body.appendChild(link);
						link.click();
					});
					setIsDownloading(false);
					setDownloadSuccess(true); // Показать уведомление об успехе
					setTimeout(() => setDownloadSuccess(false), 2000);
				})

				.catch(err => {
					setIsDownloading(false);
					console.log(err);
				});
		}
	}

	function HandleSelectCard() {
		if (
			selectedCards.find(
				(selectedCard) => selectedCard.slug === cardInfo.slug
			)
		) {
			const newArraySelectedCards = selectedCards.filter(
				(card) => card.slug !== cardInfo.slug
			);
			dispatch(changeSelectedCard([...newArraySelectedCards]));
		} else dispatch(changeSelectedCard([...selectedCards, cardInfo]));
	}

	const videoStyle = {
		borderRadius: "8px",
		objectFit: "contain",
		height: "100%",
		overflow: "hidden",
	};

	function playVideoHandler() {
		if (fileType === "Video") {
			setPlay(true);
		}
	}

	function stopVideoHandler() {
		if (fileType === "Video") {
			setPlay(false);
		}
	}

	return (
		<div
			className={`card${twoCardsOnRow ? " card_two" : ""}${selectedCards.find(
				(selectedCard) => selectedCard.slug === cardInfo.slug
			)
				? " card_selected"
				: ""
				}`}>
			<div className="card__image-actions">

				{fileType === "Image" && <button
					className="card__imageAction"
					type="button"
					onClick={HandleCopy}>
					<img src={copy} alt="copy" />
				</button>}
				<button
					className="card__imageAction"
					type="button"
					onClick={HandleDownload}>
					<img src={download} alt="download" />
				</button>
			</div>
			{isCopying && (
				<div className="card__copy">
					<img src={content_copy} alt="content_copy" />
					Копирование...
				</div>
			)}
			{isDownloading && (
				<div className="card__copy">
					<img src={content_copy} alt="content_copy" />
					Скачивание...
				</div>
			)}
			{downloadSuccess && (
				<div className="card__copy">
					<img src={content_copy} alt="content_copy" />
					Скачивание завершено
				</div>
			)}
			{copySuccess && (
				<div className="card__copy">
					<img src={content_copy} alt="content_copy" />
					Скопировано в буфер обмена
				</div>
			)}
			{copyError && (
				<div className="card__copy card__copy-error">
					<img
						onClick={() => setCopyError("")}
						src={closeOverlay}
						alt="close-overlay"
						className="popup__close-overlay"
					/>
					<img src={copyErrorImage} alt="copyError" className="card__copy-error-image" />
					<h5>Не удалось скопировать изображение!</h5>
					<h5>Не закрывайте вкладку, пока не завершится копирование</h5>
				</div>
			)}
			<button
				onMouseEnter={playVideoHandler}
				onMouseLeave={stopVideoHandler}
				className={`card__hover${twoCardsOnRow ? "card__hover_two" : ""
					}`}
				onClick={multiSelect ? HandleSelectCard : HandleOpenPopup}
				type="button"
			/>
			{fileType === "Video" ? (
				<div
					className={`card__img ${twoCardsOnRow ? "card__img_two" : ""
						}`}>
					<ReactPlayer
						url={cardInfo.file_url}
						style={videoStyle}
						playing={play}
					/>
				</div>
			) : (
				<img
					src={cardInfo.preview}
					alt="почему-то фото детали не загрузилось"
					className={`card__img ${twoCardsOnRow ? "card__img_two" : ""
						}`}
				/>
			)}
			<div className="card__info">
				<div className="card__name-author">
					<p className="card__name">{cardInfo.title}</p>
					<div className="article">Артикул: {cardInfo?.article}</div>
					{!twoCardsOnRow && (
						<div className="card__author">
							{cardInfo.author?.full_name} &#183; {cardDate}
						</div>
					)}
				</div>
				{!twoCardsOnRow && (
					<ul className="card__filters">
						{cardInfo.tags && (
							<>
								{cardInfo.tags.map((tag) => (
									<Filter
										key={tag.id}
										filter={tag}
										role={"card-tag"}
									/>
								))}
							</>
						)}
					</ul>
				)}
			</div>
		</div>
	);
};

export default Card;
